import {ContactCard} from "../cards/ContactCard"
import {Modal} from "./Modal"

type ContactModalProps = {
  activator: React.ReactElement
}

export const ContactModal = ({activator}: ContactModalProps) => {
  return (
    <Modal activator={activator}>
      {(onClose) => (
        <ContactCard
          title="Get in touch"
          description="Whether you're looking to set your foundation, scale your business, or have a question about pricing - our team is ready to help!"
          onClose={onClose}
        />
      )}
    </Modal>
  )
}
